import { defineStore } from "pinia";
import {
  InspirationBoard,
  InspirationBoardAssetResponse,
  InspirationBoardResponse,
  UploadAssetResponse,
} from "~/types/InspirationBoard";
import { AdFormat } from "~/types/shared";

export const useInspirationBoardStore = defineStore({
  id: "inspiration-board-store",
  state: () => {
    return {
      boards: [],
    } as {
      boards: Array<InspirationBoard>;
    };
  },
  actions: {
    async createBoard(dto: {
      title: string | null;
      clientId: number;
      folderId: number | null;
      description: string | undefined | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { board: InspirationBoardResponse };
      }>("/inspiration-board/create", {
        method: "POST",
        body: {
          title: dto.title === "" ? null : dto.title,
          description: dto.description === "" ? null : dto.description,
          clientId: dto.clientId,
          folderId: dto.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedInspirationBoards } = useInspirationBoard();
        const board = getMappedInspirationBoards([data.value.data.board])[0];
        this.boards.push(board);
        return { id: board.id, uuid: board.uuid };
      }
      return null;
    },

    async duplicateBoard(dto: {
      boardId: number;
      clientId: number;
      folderId: number | null;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { board: InspirationBoardResponse };
      }>(`inspiration-board/duplicate`, {
        method: "POST",
        body: {
          clientId: dto.clientId,
          boardId: dto.boardId,
          folderId: dto.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedInspirationBoards } = useInspirationBoard();
        const board = getMappedInspirationBoards([data.value.data.board])[0];
        this.boards.push(board);
        return { id: board.id, uuid: board.uuid };
      }
      return null;
    },

    async createCustomBoardAsset(dto: {
      boardId: number;
      asset: File;
      uploadProgress: (progress: number) => void;
    }): Promise<UploadAssetResponse> {
      const blobName = dto.asset.name;
      const adFormat = useInspirationBoard().fileToAdFormat(dto.asset);
      const errorMessage = "Failed to upload file";
      // Step 1: Get presigned url from backend
      const res = await this.getPresignedUrl({
        boardId: dto.boardId,
        blobName,
        adFormat,
      });
      if (typeof res === "string") {
        return [res, null];
      } else if (res == null) {
        return [errorMessage, null];
      }
      // Step 2: Upload file to presigned url
      try {
        await this.uploadFileToPresignedUrl({
          presignedUrl: res.presignedUrl,
          asset: dto.asset,
          uploadProgress: dto.uploadProgress,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [errorMessage, null];
      }
      // Step 3: Create board asset with url
      const res2 = await this.finishCreateCustomBoardAsset({
        boardId: dto.boardId,
        blobName,
        adFormat,
      });
      if (typeof res2 === "string") {
        return [res2, null];
      } else if (res2 == null) {
        return [errorMessage, null];
      }
      return [null, res2];
    },

    async getPresignedUrl(dto: {
      boardId: number;
      blobName: string;
      adFormat: AdFormat;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { presignedUrl: string };
      }>(`inspiration-board/custom-asset/start`, {
        method: "POST",
        body: {
          blobName: dto.blobName,
          boardId: dto.boardId,
          adFormat: dto.adFormat,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        return data.value.data;
      }
      return null;
    },

    uploadFileToPresignedUrl(dto: {
      presignedUrl: string;
      asset: File;
      uploadProgress: (progress: number) => void;
    }) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            dto.uploadProgress(progress);
          }
        };
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status < 200 || xhr.status >= 300) {
              reject(xhr.responseText);
            } else {
              resolve(xhr.responseText);
            }
          }
        };
        xhr.open("PUT", dto.presignedUrl, true);
        xhr.setRequestHeader("Content-Type", dto.asset.type);
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
        xhr.send(dto.asset);
      });
    },

    async finishCreateCustomBoardAsset(dto: {
      boardId: number;
      blobName: string;
      adFormat: AdFormat;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { asset: InspirationBoardAssetResponse };
      }>(`inspiration-board/custom-asset/finish`, {
        method: "POST",
        body: {
          blobName: dto.blobName,
          boardId: dto.boardId,
          adFormat: dto.adFormat,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedInspirationBoardAssets } = useInspirationBoard();
        const asset = getMappedInspirationBoardAssets([
          data.value.data.asset,
        ])[0];
        const board = this.boards.find((b) => b.id === dto.boardId);
        if (board) {
          board.assets.push(asset);
        }
        return asset;
      }
      return null;
    },

    async updateBoard(dto: {
      boardId: number;
      title?: string | undefined | null;
      description?: string | undefined | null;
    }) {
      const { error } = await useDatAdsApiFetch(
        `inspiration-board/board/${dto.boardId}`,
        {
          method: "PATCH",
          body: {
            title: dto.title === "" ? null : dto.title,
            description: dto.description === "" ? null : dto.description,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const boardIndex = this.boards.findIndex(
        (board) => board.id === dto.boardId,
      );
      if (boardIndex !== -1) {
        this.boards.splice(boardIndex, 1, {
          ...this.boards[boardIndex],
          ...dto,
        });
      }
    },

    async listBoards(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { boards: InspirationBoardResponse[] };
      }>(`inspiration-board/client/${clientId}`);
      if (error.value) {
        useErrorHandler(error.value);
        return [];
      }
      if (data.value) {
        const { getMappedInspirationBoards } = useInspirationBoard();
        const boards = getMappedInspirationBoards(data.value.data.boards);
        this.setBoards(boards);
      }
      return this.boards.filter((board) => board.clientId === clientId);
    },

    setBoards(boards: InspirationBoard[]) {
      // Remove all boards not in returned list
      this.boards = this.boards.filter((board) =>
        boards.some((r) => r.id === board.id),
      );
      // Add or update boards
      boards.forEach((b) => this.setBoard(b, true));
    },

    async getBoard(boardUuid: string) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { board: InspirationBoardResponse };
      }>(`inspiration-board/board/${boardUuid}`);
      if (error.value) {
        const errorMesage = useErrorHandler(error.value);
        return errorMesage;
      }
      if (data.value) {
        const { getMappedInspirationBoards } = useInspirationBoard();
        const board = getMappedInspirationBoards([data.value.data.board])[0];
        this.setBoard(board);
        return board;
      }
      return null;
    },

    async getBoardAsset(assetUuid: string) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { asset: InspirationBoardAssetResponse };
      }>(`inspiration-board/asset/${assetUuid}`);
      if (error.value) {
        useErrorHandler(error.value);
        return null;
      }
      if (data.value) {
        const { getMappedInspirationBoardAssets } = useInspirationBoard();
        const asset = getMappedInspirationBoardAssets([
          data.value.data.asset,
        ])[0];
        return asset;
      }
      return null;
    },

    setBoard(board: InspirationBoard, keepOldAssets = false) {
      const index = this.boards.findIndex((r) => r.id === board.id);
      if (index === -1) {
        this.boards.push(board);
      } else {
        const updatedBoard = keepOldAssets
          ? {
              ...board,
              assets: this.boards[index].assets,
            }
          : board;
        this.boards.splice(index, 1, updatedBoard);
      }
    },

    async deleteBoard(boardId: number) {
      const { error } = await useDatAdsApiFetch(
        `inspiration-board/board/${boardId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.boards.findIndex((board) => board.id === boardId);
      if (index !== -1) {
        this.boards.splice(index, 1);
      }
      return null;
    },

    async deleteBoardAsset(boardAssetId: number) {
      const { error } = await useDatAdsApiFetch(
        `inspiration-board/asset/${boardAssetId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const index = this.boards.findIndex((board) =>
        board.assets.some((asset) => asset.id === boardAssetId),
      );
      if (index < 0) {
        return null;
      }
      const assetIndex = this.boards[index].assets.findIndex(
        (asset) => asset.id === boardAssetId,
      );
      if (assetIndex !== -1) {
        this.boards[index].assets.splice(assetIndex, 1);
      }
      return null;
    },

    async updateBoardAssetNotes(boardAssetId: number, notes: string | null) {
      // Optimistic update
      const boardIndex = this.boards.findIndex((board) =>
        board.assets.some((asset) => asset.id === boardAssetId),
      );
      if (boardIndex < 0) {
        return;
      }
      const assetIndex = this.boards[boardIndex].assets.findIndex(
        (asset) => asset.id === boardAssetId,
      );
      if (assetIndex < 0) {
        return;
      }
      this.boards[boardIndex].assets[assetIndex].notes = notes;
      // Update on server
      const { error } = await useDatAdsApiFetch(
        `inspiration-board/asset/${boardAssetId}/notes`,
        {
          method: "PATCH",
          body: {
            notes: notes === "" ? null : notes,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },
  },
  getters: {
    getBoardsOfClient: (state) => (clientId: number) => {
      return state.boards.filter((board) => board.clientId === clientId);
    },

    getBoardById: (state) => (boardId: number) => {
      return state.boards.find((board) => board.id === boardId) ?? null;
    },

    getBoardByUuid: (state) => (boardUuid: string) => {
      return state.boards.find((board) => board.uuid === boardUuid) ?? null;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInspirationBoardStore, import.meta.hot),
  );
}
